import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from './../BootstrapTable';
import { useColumnTableHeaderDispatchOrder, useCity, useCountry, useMoneyTransaction } from './../../config/use-state/order';
import { removeDispatchOrder } from './../../actions/dispath.actions';

const OrderTable = ({ orders, id, singleSelect, search, clickToSelect, height, pagination, onChange, dispatchState }) => {

    useEffect(() => {
        $(document).on('click', '.btn-remove', (e) => {
            const orderId = $(e.currentTarget).data('rowCustomId');
            const res = confirm('Estás seguro ?');
            if (res) {
                removeDispatchOrder({ order_id: orderId }).then(res => {
                    if (onChange) {
                        onChange('removed row');
                    }
                }).catch(error => console.log(error));
            }
        })
    }, [])

    const orderMap = useCallback((order, index) => {
        const customer = order?.relationships?.customer?.meta?.data
        const orderType = order?.relationships?.order_type?.meta?.data
        const originCountry = useCountry(order);
        const originCity = useCity(order);

        const destinationCountry = useCountry(order, 'destination');
        const destinationCity = useCity(order, 'destination');

        let row = {
            index: index + 1,
            customer: `${customer?.attributes?.name} ${customer?.attributes?.last_name}`,
            order_type: orderType.attributes?.name,
            order_number: `<a href="/orders/detail?id=${order.id}" target="_blank">${order.attributes?.order_number}</a>`,
            total_amount: `$ ${order.attributes.total_amount}`,
            order_datetime: order.attributes?.order_datetime,
            order_origin: `${originCountry.attributes.name} - ${originCity.attributes.name}`,
            order_destination: `${destinationCountry.attributes.name} - ${destinationCity.attributes.name}`
        }
        if (dispatchState == 1) {
            row = {
                ...row,
                actions: `<button class='btn btn-remove' data-row-custom-id=${order.id}>
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash" fill="red" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                        </svg>
                        </button>`
            }
        }

        return row;
    }, [orders]);

    const columns = useColumnTableHeaderDispatchOrder(dispatchState)
    return (
        <BootstrapTable
            id={id}
            search={search}
            rows={orders.map((order, index) => (orderMap(order, index)))}
            clickToSelect={clickToSelect}
            pagination={pagination}
            height={height}
            singleSelect={singleSelect}
            columns={columns} />
    )
}

OrderTable.propTypes = {
    orders: PropTypes.array,
    id: PropTypes.string,
    search: PropTypes.bool,
    singleSelect: PropTypes.bool,
    clickToSelect: PropTypes.bool,
    height: PropTypes.number,
    pagination: PropTypes.bool
}

OrderTable.defaultProps = {
    orders: [],
    search: true,
    singleSelect: false,
    clickToSelect: false,
    height: 460,
    pagination: false
}

export default OrderTable;
