import React, { useMemo } from 'react';

export const useColumnTableHeaderDispatchOrder = (state) => {
    let columns = [
        { title: '#', field: 'index' },
        { title: 'Codigo', field: 'order_number' },
        { title: 'Cliente', field: 'customer' },
        { title: 'Tamaño de la Caja', field: 'order_type' },
        { title: 'Valor de la Caja', field: 'total_amount' },
        { title: 'Fecha de registro', field: 'order_datetime' },
        { title: 'Origen', field: 'order_origin' },
        { title: 'Destino', field: 'order_destination' },
    ]

    if (state == 1) {
        columns = [
            ...columns,
            { field: 'actions' }
        ]
    }
    return columns;
}

export const useCustomer = (order) => {
    return useMemo(() => {
        return order?.relationships?.customer?.meta?.data
    }, [order?.relationships?.customer])
}

export const useOrderType = (order) => {
    return useMemo(() => {
        return order?.relationships?.order_type?.meta?.data
    }, [order?.relationships?.order_type])
}

export const useCountry = (order, typeCountry = "origin") => {
    if (typeCountry === 'origin') {
        return order?.relationships?.origin?.meta?.data?.relationships?.country?.meta?.data
    } else {
        return order?.relationships?.destination?.meta?.data?.relationships?.country?.meta?.data
    }
}

export const useCity = (order, typeCity = "origin") => {
    if (typeCity === 'origin') {
        return order?.relationships?.origin?.meta?.data?.relationships?.state?.meta?.data
    } else {
        return order?.relationships?.destination?.meta?.data?.relationships?.state?.meta?.data
    }
}

export const useItemCategory = (order) => {
    return useMemo(() => {
        return order?.relationships?.item_category?.meta?.data
    }, [order?.relationships?.item_category])
}


export const useMoneyTransaction = order => {
    return useMemo(() => {
        return order?.relationships?.money_transaction?.meta?.data
    }, [order?.relationships?.money_transaction])
}
