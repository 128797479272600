import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const BootstrapModal = ({ children, showModal, modalId, onClose }) => {
    useEffect(() => {
        const jqueryTagModal = $(`#${modalId}`);
        if (showModal && jqueryTagModal.modal) {
            jqueryTagModal.modal('show');
        } else {
            if (jqueryTagModal.modal) {
                $(`#${modalId}`).modal('hide');
            }
        }
        $(`#${modalId}`).on('hide.bs.modal', (e) => {
            // do something...
            if (onClose) {
                onClose()
            }
        });
    }, [showModal]);
    return (
        <div className="modal fade bootstrapModalLarge"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="bootstrapModalLarge"
            id={modalId}
            aria-hidden="true"
            data-backdrop="static"
            data-keyboard="false">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    {children}
                </div>
            </div>
        </div>
    );
};

BootstrapModal.propTypes = {
    modalId: PropTypes.string,
    showModal: PropTypes.bool,
    onClose: PropTypes.func,
};

BootstrapModal.defaultProps = {
    modalId: 'exampleModal',
    showModal: false,
    onClose: () => null,
};
export default BootstrapModal;