import React, { useState, useEffect } from 'react';
import AddItemOrderModal from './AddItemOrderModal';
import OrderTable from './OrderTable';
import propTypes from 'prop-types';
import * as dispatchAction from './../../actions/dispath.actions';
import { useDispatchAmountLimit } from './../../config/use-state/dispatch';
import Preloader from './../Preloader';

const orderReadyId = 'table-order-already';

const AddItemOrder = (props) => {
    const [orderModal, setOrderModal] = useState(false);
    const [fetchingOrders, setFetchingOrders] = useState(false);
    const [orders, setOrders] = useState([]);
    const [currentAmount, setCurrentAmount] = useState(0);
    const dispatch = props.dispatch;
    const amountLimit = useDispatchAmountLimit(dispatch).attributes.limit;
    const toggleOrderModal = (flag) => {
        setOrderModal(flag);
    };

    const fetchDispatchOrders = () => {
        setFetchingOrders(true);
        dispatchAction.listDispatchOrder({ id: dispatch.id }).then(res => {
            setFetchingOrders(false);
            setOrders(res.data.payload.data);
            setCurrentAmount(calculateDispatchAmount(res.data.payload.data))
        }).catch(error => {
            setFetchingOrders(false);
            console.log(error);
        })
    }

    useEffect(() => {
        console.log(dispatch);
        fetchDispatchOrders();
    }, []);

    const onAddOrder = () => {
        //todo fetch or refresh orders
        fetchDispatchOrders();
    }

    const onCurrentAmountChange = amount => {
        setCurrentAmount(amount);
    }

    const calculateDispatchAmount = orders => {
        return orders.reduce((a, order) => a = a + order.attributes.total_amount, 0)
    }

    return (
        <div className="section border p-2 rounded mb-2">
            <div className="section-header d-flex justify-content-between align-items-center">
                <h5 className="text-secondary">Contenido de Guía (Órdenes)</h5>
                <div>
                    {dispatch.attributes.dispatch_state_id == 1 ?
                        <button onClick={() => toggleOrderModal(true)} className="btn btn-outline-primary btn-sm">
                            Agregar Orden
                        </button> :
                        null
                    }
                </div>
            </div>
            {dispatch.attributes.item_category_id == 1 ?
                <>
                    <div className="field">
                        <span className="text-muted mr-1">Límite:</span>
                        <span className="text-danger"><strong>$ {amountLimit}</strong></span>
                    </div>
                    <div className="field">
                        <span className="text-muted mr-1">Valor Actual:</span>
                        <span className="text-success"><strong>$ {currentAmount}</strong></span>
                    </div>
                </>
                : null
            }
            <OrderTable onChange={onAddOrder} id={orderReadyId} orders={orders} search={false} dispatchState={dispatch.attributes.dispatch_state_id} />
            <AddItemOrderModal
                dispatch={dispatch}
                amountLimit={amountLimit}
                currentAmount={currentAmount}
                onCurrentAmountChange={onCurrentAmountChange}
                onAdd={onAddOrder}
                showModal={orderModal}
                onClose={(flag) => toggleOrderModal(flag)} />
        </div>
    )
}


AddItemOrder.propTypes = {
    dispatch: propTypes.object.isRequired
}

export default AddItemOrder;