import axios from 'axios';
import { csrfToken } from '@rails/ujs';
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken();
import * as apiRoutes from './../config/api-routes';

export const listOrderPending = async (params) => {
    const res = axios.get(apiRoutes.DISPATCH_PENDING_ORDERS, {
        params
    })
    return res;
}

export const listOrderPendingByItemCategory = async (params) => {
    const res = axios.get(apiRoutes.DISPATCH_PENDING_ORDERS_BY_ITEM_CATEGORY, {
        params
    })
    return res;
}

export const addOrderToDispatch = async (params) => {
    const res = axios.post(apiRoutes.DISPATCH_ADD_ORDER, params)
    return res;
}

export const listDispatchOrder = async (params) => {
    const res = axios.get(apiRoutes.DISPATCH_LIST_ORDER, {
        params
    })
    return res;
}

export const removeDispatchOrder = async (params) => {
    const res = axios.post(apiRoutes.DISPATCH_REMOVE_ORDER, params)
    return res;
}

export const getItemCategories = async (params) => {
    const res = axios.get(apiRoutes.MODEL_DEPENDENCIES, {
        params
    })
    return res;
}
