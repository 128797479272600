import React from 'react';
import propTypes from 'prop-types';

const Preloader = ({ loading, children }) => {
    return (
        loading ? <div className={'d-flex justify-content-center align-items-center'} style={{ minHeight: '100%' }}>
            <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div> : children
    );
};

Preloader.propTypes = {
    loading: propTypes.bool
};

Preloader.defaultProps = {
    loading: false
};

export default Preloader;