import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import BootstrapModal from '../BootstrapModal';
import * as dispatchActions from './../../actions/dispath.actions';
import Preloader from './../Preloader';
import { useCustomer, useOrderType, useCountry, useCity, useItemCategory, useMoneyTransaction } from './../../config/use-state/order';
import { debounce } from './../../helpers/functions';

const OrderItem = ({ order, onAddOrRemove }) => {
    const customer = useCustomer(order);
    const orderType = useOrderType(order);
    const originCountry = useCountry(order);
    const originCity = useCity(order);
    const itemCategory = useItemCategory(order);
    const moneyTransaction = useMoneyTransaction(order);

    const destinationCountry = useCountry(order, 'destination');
    const destinationCity = useCity(order, 'destination');

    const addOrRemove = (order) => {
        onAddOrRemove(order);
    }

    return (
        <li className="list-group-item">
            <div className="field">
                <span className="text-muted mr-1">Cliente:</span>
                <span>{`${customer?.attributes?.name} ${customer?.attributes?.last_name}`}</span>
            </div>


            <div className="field">
                <span className="text-muted mr-1">Tipo de Orden:</span>
                <span>{orderType?.attributes?.name}</span>
            </div>


            <div className="field">
                <span className="text-muted mr-1">Fecha de Registro:</span>
                <span>{order.attributes.order_datetime}</span>
            </div>


            <div className="field">
                <span className="text-muted mr-1">Número de Orden:</span>
                <span className={order.matched ? 'highlight' : null}>{order.attributes.order_number}</span>
            </div>


            <div className="field">
                <span className="text-muted mr-1">TFI:</span>
                <span className={order.matched ? 'highlight' : null}>{order.attributes.internal_code}</span>
            </div>


            <div className="field">
                <span className="text-muted mr-1">Datos de Origen:</span>
                <span>{originCountry.attributes.name} - {originCity.attributes.name}</span>
            </div>


            <div className="field">
                <span className="text-muted mr-1">Datos de Destino:</span>
                <span>{destinationCountry.attributes.name} - {destinationCity.attributes.name}</span>
            </div>

            <div className="field">
                <span className="text-muted mr-1">Tipo de Contenido:</span>
                <span className="text-primary"><strong>{itemCategory.attributes.name}</strong></span>
            </div>

            <div className="field">
                <span className="text-muted mr-1">Valor de la Orden:</span>
                <span className="text-success"><strong>$ {order.attributes.total_amount}</strong></span>
            </div>

            <span className='float-right'>
                <button className='btn' onClick={() => addOrRemove(order)}>
                    {
                        order.selected ? <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-trash" fill="red" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                            <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                        </svg> : <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-plus" fill="green" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                    }
                </button>
            </span>
        </li>
    )
}

const OrderCards = ({ orders, onAddOrRemove }) => {
    return (
        <div className="card">
            <div className="card-header bg-info text-white">
                Por favor selecciona las ordenes que deseas agregar
            </div>
            <ul className="list-group list-group-flush overflow-auto" style={{ height: '300px' }}>
                {orders.map((order) => {
                    return (
                        <OrderItem order={order} key={order.id} onAddOrRemove={onAddOrRemove} />
                    )
                })}

            </ul>
        </div>
    )
}

const AddItemOrderModal = ({ showModal, onClose, onAdd, dispatch, amountLimit, currentAmount, onCurrentAmountChange }) => {
    const [pendingOrders, setPendingOrders] = useState([]);
    const [itemCategories, setItemCategories] = useState([]);
    const [itemCategorySelected, setItemCategorySelected] = useState(0);
    const [requesting, setRequesting] = useState(false);
    const [resultOrder, setResultOrder] = useState(null);
    const [valueSearch, setValueSearch] = useState('');
    const [addedAmount, setAddedAmount] = useState(0);

    useEffect(() => {
        if (showModal) {
            //todo 
            setRequesting(true);
            dispatchActions.listOrderPendingByItemCategory({ id: dispatch.id, item_category_id: dispatch.attributes.item_category_id }).then(res => {
                setPendingOrders(res.data.payload.data.map(order => ({ ...order, selected: false, matched: false })));
                setRequesting(false);
                setResultOrder(null);
            }).catch(error => {
                console.log(error);
                setRequesting(false);
            })

            dispatchActions.getItemCategories({ filters: ["item_categories"] }).then(res => {
                setItemCategories(res.data.payload.item_categories.data);
                setRequesting(false);
            }).catch(error => {
                console.log(error);
                setRequesting(false);
            })
        } else {
            setPendingOrders([]);
        }
    }, [showModal])

    const addToDispatch = () => {
        const processOrders = pendingOrders.filter((order) => order.selected);
        if (processOrders.length > 0) {
            setRequesting(true);
            dispatchActions.addOrderToDispatch({ orders_id: processOrders.map(order => order.id), id: dispatch.id }).then(res => {
                setRequesting(false);
                setAddedAmount(0);
                onAdd(processOrders);
                onClose(false);
            }).catch(error => {
                setRequesting(false);
            })
        } else {
            alert('No has agregado ninguna orden');
        }
    }

    const onCloseModal = () => {
        onCurrentAmountChange(currentAmount - addedAmount);
        setAddedAmount(0);
        setItemCategorySelected(0);
        onClose(false);
    }

    // const filteredOrders = useMemo(() => {
    //     const querySearch = valueSearch
    //     const orderSearched = pendingOrders.filter((pendingOrder) => pendingOrder.attributes.order_number === querySearch)
    //         .map(order => ({ ...order, matched: true }))
    //     return orderSearched;
    // })

    const onAddOrRemove = (order) => {
        const newOrders = pendingOrders.map((mapOrder) => {
            let newOrder = {
                ...mapOrder
            }
            if (mapOrder.id === order.id) {
                if (dispatch.attributes.item_category_id == 1) {
                    if (!order.selected && order.attributes.total_amount + currentAmount <= amountLimit) {
                        newOrder.selected = true
                        setAddedAmount(addedAmount + order.attributes.total_amount);
                        onCurrentAmountChange(currentAmount + order.attributes.total_amount);
                    } else if (!order.selected && order.attributes.total_amount + currentAmount > amountLimit) {
                        alert("Excede el monto límite.");
                    } else if (order.selected) {
                        newOrder.selected = false;
                        setAddedAmount(addedAmount - order.attributes.total_amount);
                        onCurrentAmountChange(currentAmount - order.attributes.total_amount);
                    }
                } else {
                    newOrder.selected = !order.selected;
                }
            }
            return newOrder
        });
        setPendingOrders(newOrders)
    }

    const onChangeInputSearch = (event) => {
        setValueSearch(event.target.value);
    }

    const onKeyDownSearch = (event) => {
        // enter has keyCode = 13, change it if you want to use another button
        if (event.key == "Enter") {
            filteredOrders.map((order) => {
                onAddOrRemove(order);
                setValueSearch('');
            })
            return false;
        }
    }

    const onSelectChange = event => {
        setItemCategorySelected(event.target.value);
    }

    const filteredOrders = () => {
        // return pendingOrders.filter(order => {
        //     return order.relationships.item_category.data.id == itemCategorySelected
        // });
        return pendingOrders.filter(order => {
            return order.attributes.internal_code.includes(valueSearch)
        })
    }

    return (
        <>
            <BootstrapModal modalId={'addItemOrderModal'} showModal={showModal} onClose={() => onClose(false)}>
                <div className="modal-header">
                    <h5 className="modal-title">Agregar Item</h5>
                    <button type="button" className="close" onClick={onCloseModal} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Preloader loading={requesting}>
                        {dispatch.attributes.item_category_id == 1 ?
                            <>
                                <div className="field">
                                    <span className="text-muted mr-1">Límite:</span>
                                    <span className="text-danger"><strong>$ {amountLimit}</strong></span>
                                </div>
                                <div className="field">
                                    <span className="text-muted mr-1">Valor Actual:</span>
                                    <span className="text-success"><strong>$ {currentAmount}</strong></span>
                                </div>
                            </>
                            : null
                        }
                        <h5 className={'text-center'}>Ordenes por Procesar</h5>
                        <input autoComplete="off" id={'filter-order'} value={valueSearch} onChange={onChangeInputSearch} type="text" className={'form-control my-1'} placeholder={'Escanea o escribe el TFI de la orden adjuntada en la caja'} />
                        {/* <select id={'filter-order'} type="text" className={'form-control my-1'} onChange={onSelectChange}>
                            <option value="">-- Seleccione el tipo de contenido que desea buscar --</option>
                            {itemCategories.map(category => {
                                return (
                                    <option key={category.id} value={category.id}>
                                        {category.attributes.name}
                                    </option>
                                )
                            })}
                        </select> */}
                        {/* <small className="form-text text-info">Resultados encontrados: {filteredOrders.length}</small> */}
                        <small className="form-text text-muted">Solo se listan las ordenes confirmadas</small>
                        {
                            valueSearch ? <OrderCards
                                onAddOrRemove={onAddOrRemove} orders={filteredOrders()} /> :
                                <OrderCards orders={pendingOrders} onAddOrRemove={onAddOrRemove} />
                        }
                    </Preloader>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={addToDispatch}>Guardar</button>
                    <button type="button" className="btn btn-secondary" onClick={onCloseModal}>Cerrar</button>
                </div>
            </BootstrapModal>
        </>
    )
}

AddItemOrderModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
}

AddItemOrderModal.defaultProps = {
    showModal: false,
    onClose: () => null,
}

export default AddItemOrderModal;