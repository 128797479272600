import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

const BootstrapTable = (props) => {
    const handleLoad = () => {
        reinitializeBootstrapTable();
    }

    const reinitializeBootstrapTable = () => {
        const tableTagJquery = $(`#${props.id}`);
        if (tableTagJquery.bootstrapTable) {
            tableTagJquery.bootstrapTable('destroy').bootstrapTable({
                search: props.search,
                clickToSelect: props.clickToSelect,
                singleSelect: props.singleSelect,
                checkboxHeader: false,
                columns: props.columns
            });
            tableTagJquery.bootstrapTable('load', props.rows)
            tableTagJquery.bootstrapTable('uncheckAll');
        }
    }

    const destroyBootstrapTable = () => {
        const tableTagJquery = $(`#${props.id}`);
        if (tableTagJquery.bootstrapTable) {
            tableTagJquery.bootstrapTable('destroy');
        }
    }

    const refreshBootstrapTable = () => {
        const tableTagJquery = $(`#${props.id}`);
        if (tableTagJquery.bootstrapTable) {
            // tableTagJquery.bootstrapTable();
            tableTagJquery.bootstrapTable('refresh');
            // tableTagJquery.bootstrapTable('refreshOptions', { search: props.search, columns: props.columns });
        }
    }

    useEffect(() => {
        window.addEventListener('load', handleLoad);
        return () => {
            window.addEventListener('remove', handleLoad);
        }
    }, []);

    useEffect(() => {
        reinitializeBootstrapTable();
        return () => {
            //    destroyBootstrapTable();
        }
    })

    return <table id={props.id} className={'table-sm'} />
}

BootstrapTable.propTypes = {
    id: PropTypes.string.isRequired,
    search: PropTypes.bool,
    clickToSelect: PropTypes.bool,
    singleSelect: PropTypes.bool,
    columns: PropTypes.array,
    rows: PropTypes.array,
}

BootstrapTable.defaultProps = {
    search: true,
    clickToSelect: false,
    singleSelect: false,
    columns: [],
    rows: []
}

export default BootstrapTable;